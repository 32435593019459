import flux from '@aust/react-flux';

const cb_store = flux.addStore('connectButton', {
    'text': 'Connect Wallet',
    'wall': '',
});

const modal_store = flux.addStore('modal', {
    'open': false,
    'completed': false,
    'content': [],
    'cb': null
});

const page_store = flux.addStore('page', {
    'page': '',
    'loading': true,
    'content': []
});

const amb_store = flux.addStore('amb', {
    'index': 0,
    'data': null,
    'meta': {}
});

const notices_store = flux.addStore('notices', {
    'active': false,
    'success': '',
    'error': ''
});

const loading_store = flux.addStore('loading', {
    'open': false,
});

loading_store.register('loading/open', (state, open) => (state) => ({ ...state, open }));

amb_store.register('amb/index', (dispatch, index) => (state) => ({ ...state, index }));
amb_store.register('amb/data', (dispatch, data) => (state) => ({ ...state, data }));
amb_store.register('amb/new_meta', (dispatch, index, new_data) => (state) => {
    state['meta'][index] = new_data;
    return { ...state };
});

amb_store.addSelector('getMeta', (state, index) => amb_store.selectState('meta')[index] );
amb_store.addSelector('getMeta', (state, index) => amb_store.selectState('meta')[index] );
window.amb_store = amb_store;

window.flux = flux;


notices_store.register('notices/error', (dispatch, error) => (state) => ({ ...state, error }));
notices_store.register('notices/success', (dispatch, success) => (state) => ({ ...state, success }));

modal_store.register('modal/setOpen', (dispatch, open) => (state) => ({ ...state, open }));
modal_store.register('modal/setContent', (dispatch, content) => (state) => ({ ...state, content }));
modal_store.register('modal/setCb', (dispatch, cb) => (state) => ({ ...state, cb }));
modal_store.register('modal/complete', (dispatch, complete) => (state) => ({ ...state, complete }));

modal_store.addSelector('getCb', (state) => modal_store.selectState('cb'));

cb_store.register('connectButton/Text', (dispatch, text) => (state) => ({ ...state, text }));

page_store.register('page/change', (dispatch, page, force) => (state) => {
    window.wallet_cb = null;

    if (page === state.page && force !== true) {
        return state;
    }

    state.content = [];
    state.loading = true;
    state.page = page;

    return { ...state };
});
page_store.register('page/loading', (dispatch, loading) => (state) => ({ ...state, loading }));
page_store.register('page/content', (dispatch, content) => (state) => ({ ...state, content }));
