import { useEffect } from 'react';
import flux from '@aust/react-flux';

export function Notices(props) {
    const error = flux.notices.useState('error');
    const success = flux.notices.useState('success');

    let message = error || success;
    let type = error ? true : false;

    useEffect(() => {
        if (message !== '') {
            setTimeout(() => {
                flux.dispatch('notices/error', '');
                flux.dispatch('notices/success', '');
            }, 5000);
        }
    }, [message])

    if (message === '') {
        return <></>
    }

    return (
        <div className='fixed inset-x-1/2 top-12 tlcenter w-fit z-20'>
            <span className={(type ? 'bg-red-600' : 'bg-green-600') + ' rounded-sm pl-2 pr-2 pt-1 pb-1'}>
                {message}
            </span>
        </div>
    )
}
