import { setModal } from './connectButton';
import * as sw3 from '@solana/web3.js';
import { runMintContract } from './mintContract';

export async function mintAmoeboy() {
    if (!window.wallet) {
        window.wallet_cb = mintAmoeboy;
        setModal();
        return;
    }

    let minter_program = 'AmoboYePPFZZ2GrEzTTWkCPeE7vmD4cCEtEmTcyeKPkX',
    mpk = new sw3.PublicKey(minter_program),
    pk_buffer = Array.from(window.wallet.publicKey.toBuffer()),
    amoeboy = [97, 109, 111, 101, 98, 111, 121];

    for (let i = 0; i < 25; i++) {
        amoeboy.push(pk_buffer[i]);
    }

    let seed = new Uint8Array(amoeboy);

    function getMint() {
        return sw3.Keypair.fromSeed(seed);
    }

    async function accountsCb(accounts) {
        let key = (await sw3.PublicKey.findProgramAddress(
            [
                seed,
                mpk.toBuffer(),
            ],
            mpk
        ));

        let account_11 = {pubkey: key[0], isSigner: false, isWritable: true};

        accounts.push(account_11);

        return accounts;
    }

    runMintContract(minter_program, 'F2C7H6WGnTN9eSTdW6cRushxK9zxwL99NkfGTY86tywU', getMint, accountsCb);
    //runMintContract(minter_program, 'F2C7H6WGnTN9eSTdW6cRushxK9zxwL99NkfGTY86tywU', null, accountsCb);
}
