import { Img, Button, Loading, Paragraph, A } from './baseComponents';
import flux from '@aust/react-flux';
import { Gallery } from './Gallery';
import { MarketItem } from './MarketItem';
import { MassTransfer } from './MassTransfer';
import { UpgradeParentHolder, UpgradeDivHolder, Amoeboy, Upgrades} from './Upgrades';
import { useEffect  } from 'react';
import { mintAmoeboy } from './web3/mintAmoeboy';
import { getTokenList } from './web3/getTokenList';

export function Body(props) {
    const loading = flux.page.useState('loading');
    const page    = flux.page.useState('page');
    const content = flux.page.useState('content');

    useEffect(() => {
        if (loading) {
            loadPage(page);
        }
    }, [loading, page]);

    if (loading) {
        return (
            <div className={props.className + ''}>
                <div className='mt-8'>
                    <Loading
                    />
                </div>
            </div>
        )
    }

    return (
        <div className={props.className + ''}>
            {content}
        </div>
    )
}

function loadPage(page) {
    console.log(page);
    switch(page) {
        case '':
            mainPage();
            break;
        case 'mint':
            mintPage();
            break;
        case 'upgrade':
            upgradePage();
            break;
        case 'tools':
            toolsPage();
            break;
        case 'massTransfer':
            massTransfer();
            break;
        default:
            break;
    }
}

async function massTransfer() {
    let token_list = await getTokenList(),
    is_holder = false;

    for (let i = 0, l = token_list[2].length; i < l; i++) {
        let mdata = token_list[2][i];
        if (mdata.data.symbol === 'AMBT' || mdata.data.symbol === '404') {
            is_holder = true;
        }
    }

    if (is_holder === false) {
        flux.dispatch('notices/error', 'You\'re not a holder.');
        flux.dispatch('page/change', '');
        flux.dispatch('page/loading', false);
    } else {
        const content = (
            <MassTransfer tokens={token_list[2]} />
        )

        flux.dispatch('page/content', content);
        flux.dispatch('page/loading', false);
    }
}

async function toolsPage() {
    const content = (
        <>
            <Button
                className='mr-8'
                text='Mass Transfer'
                onClick={() => { flux.dispatch('page/change', 'massTransfer') }}
            />
        </>
    )

    flux.dispatch('page/content', content);
    flux.dispatch('page/loading', false);
}

async function mintPage() {
    const paragraphs = [
        {
            Header: 'Amoeboy?',
            Text: 'Amoeboy is both a free NFT and a browser extension that allows you to turn your NFT collection into digital pets. We aim to provide fun and utility for every single person who owns an NFT.',
            Fragment: (<p className='mt-4'>You can find the extension <A href='https://chrome.google.com/webstore/detail/amoeboy/mmclhjiglcbhgmdpimklhmmcnjcadbpm'>here!</A></p>)
        },
        {
            Header: 'Mint?',
            Text: "You don't need to mint an Amoeboy to use the extension, but if you want to unlock stuff, you'll need to.",
            Fragment: (<p className='mt-4'> There can only be one mint per wallet. So this will be YOUR Amoeboy</p>)
        },
        {
            Header: "Ok, is there a cost?",
            Text: "Amoeboy is free to mint! The only costs associated with minting an Amoeboy are Solana gas fees.",
            Fragment: (<p className='mt-4'>P.S. You'll be listed as a creator on your Amoeboy!</p>)
        }
    ];


    const content = (
        <div key='1' className='m-8 h-full w-full flex flex-row items-center flex-nowrap'>
            <div key='2' className='flex flex-col items-center justify-start max-w-md mx-0'>
                {paragraphs.map((e) => {
                    return (
                        <Paragraph className=' border-2 border-opacity-20 bg-transp border-medium  rounded shadow-sm p-4 ' key={e.header} header={e.Header} text={e.Text} fragment={e.Fragment} />
                    )
                })}
            </div>
            <div key='3' className='flex-grow flex flex-col items-center min-h-36 justify-around'>
                <Img key='4' name='amoeboyThree' className='max-h-96 flex-grow' />
                <Button key='5' className='mt-8 text-xl rounded'
                    onClick={mintAmoeboy}
                    text='Mint Now'
                />
            </div>
        </div>
    )

    flux.dispatch('page/content', content);
    flux.dispatch('page/loading', false);
}

async function upgradePage() {
    let token_list = await getTokenList();

    const content = (
        <>
            <div key='1' className='flex flex-row w-full'>
                <UpgradeParentHolder name='Amoeboy' className='w-fit'>
                    <UpgradeDivHolder>
                        {token_list[0].length > 0 ?
                            <>
                                {token_list[0].map((e, i) => {
                                    return (
                                        <Amoeboy i={i} 
                                            real_data={e.data}
                                            key={e.data.data.name}
                                            data={e.data.data}
                                            mk={e.data.mint}
                                        />
                                    )
                                })}
                            </>
                            :
                            <span key='6'>No Amoeboy found.</span>
                        }
                    </UpgradeDivHolder>
                </UpgradeParentHolder>
                <UpgradeParentHolder options={true} className='flex-grow'>
                    <UpgradeDivHolder>
                        {token_list[1].length > 0 ?
                            <>
                                {token_list[1].map((e) => {
                                    return (
                                        <Upgrades key={e.data.data.uri} data={e.data.data} mk={e.data.mint} />
                                    )
                                })}
                            </>
                            :
                            <span key='7'>You don't have any upgrades!</span>
                        }
                    </UpgradeDivHolder>
                </UpgradeParentHolder>
            </div>
        </>
    )

    flux.dispatch('notices/error', 'This page is under construction!');
    flux.dispatch('page/loading', false);
    flux.dispatch('page/content', content);
}

async function mainPage() {
    //await new Promise(r => setTimeout(r, 2000));

    const UPGRADE = 1;
    const CC = 2;

    const items = [
        {
            name: "Sol Street Posse",
            image: "ssp" + Math.floor(Math.random() * 6 + 1) + ".gif",
            banner: "sspBanner.png",
            desc: "Our first community-creator highlight! @SpraycanHitman",
            qty: 500,
            badges: CC,
            main: true,
            index: "3TkM2FbGinkAsZVuMP69JBUZRC4tUSJpEZK3yrKeBXxG",
            p: 5
        },
        {
            name: "404 Frenz",
            image: "fren" + Math.floor(Math.random() * 6 + 1) + ".gif",
            banner: "404banner",
            desc: "Cute lil' computer guys doubling as upgrades for Amoeboy!",
            qty: 404,
            main: true,
            badges: UPGRADE,
            index: "HR3nZEBEY55dZUhkFEyhw8j3FxyW3VNfzzsqW4Q3JumV",
            p: 50
        },
        {
            skip: false,
            name: "Red Shell",
            image: "amoeboy_red.png",
            banner: "amoeboy_banner.png",
            desc: "",
            qty: 'inf',
            badges: UPGRADE,
            main: true,
            index: "BvpWz4eMYbcngp6cMDNf9XfjRrhKShSMbm1mAZ2SMd65",
            p: 40,
        },
        {
            name: "Green Shell",
            image: "amoeboy_green.png",
            qty: 'inf',
            badges: UPGRADE,
            main: false,
            index: "3U4RDcAYj8zDgeRf4WkQeyMqdStyQiBouJqUCwbqcVzc",
            p: 40,
        },
        {
            name: "Blue Shell",
            image: "amoeboy_blue.png",
            qty: 'inf',
            badges: UPGRADE,
            main: false,
            index: "GopaC5B8m7K5LeMWUUtjgyvRjKFaU3fV3Env7kLgFJsA",
            p: 40,
        },
        {
            name: "Purple Shell",
            image: "amoeboy_purple.png",
            qty: 'inf',
            badges: UPGRADE,
            main: false,
            index: "6jg4ww3f3SD2dKR6updEycJUm5o9rfZEzEVeRf7aL1Hv",
            p: 40,
        },
    ];

    let content =
        (
            <>
                <Gallery key='1' items={items} />
                <div key='2' className='-mt-4 h-auto flex max-w-6xl flex-wrap items-center justify-center'>
                    {items.map((e) => {
                        return (
                            <MarketItem key={e.name} {...e}>
                            </MarketItem>
                        )
                    })}
                </div>
            </>
        )

    flux.dispatch('page/loading', false);
    flux.dispatch('page/content', content);
}
