import { useRef, useState, useEffect } from 'react';
import { Img, SpinLoading } from './baseComponents';

export function Gallery(props) {
    const skipLoad = useRef(false);
    const timeout  = useRef(false);
    const [imagesLoaded, loadImages] = useState(false);
    const [imgIx, setImgIndex] = useState(null);

    const mp_items = props.items.filter((e) => {
        return e.main || false === true;
    });

    useEffect(() => {
        timeout.current = setTimeout(() => {
            setImgIndex((imgIx + 1) % mp_items.length);
        }, 7500);

        return () => { clearTimeout(timeout.current); }
    }, [imgIx, mp_items]);

    useEffect(() => {
        if (!skipLoad.current) {
            const preLoadImages = async () => {
                mp_items
                .forEach((e) => {
                    (async () => {
                        let img = new Image();
                        img.src = "/images/" + e.image;
                        await img.decode();
                    })();
                });
                loadImages(true);
                setImgIndex(0);
                skipLoad.current = true;
            }

            preLoadImages();
        }
    }, [skipLoad, mp_items]);

    if (imagesLoaded === false) {
        return (
            <div className={props.className + ''}>
                <SpinLoading className='' />
            </div>
        )
    }

    return (
        <div className='flex flex-col items-center h-gallery' >
            {mp_items.map((e, i) => {
                let where = i < imgIx ? 'tlxneg' : 'tlxpos',
                sel   = i === imgIx;
                let img = e.banner;
                if (sel) { where = '' }
                return (
                    <div key={i} className={where + ' flex items-end td-1000 absolute transition-transform w-screen -z-1 top-0 h-gallery overflow-hidden'}>
                        {((e.skip ?? true) !== false) &&
                        <span className='w-fit self-center relative left-1/4 mt-48'>
                                <h2 className='mt-2 text-medium text-3xl italic bold'>{e.name}</h2>
                                <p className=''>{e.desc}</p>
                        </span>
                        }
                        <Img className='opacity-30 -z-1 absolute rounded-sm align-bottom inline-block w-screen' name={img} />
                        <div className='rev-shadow w-screen absolute h1 left-0'/>
                    </div>
                )
            })}
            <div className='flex items-center w-fit absolute inset-y-1/2'>
            {mp_items.map((e, i) => {
                const sel = i === imgIx;
                return (
                    <div key={i + 'a'}
                        className={'hover:opacity-100 cursor-pointer h-6 w-6 z-10 ' + (!sel && 'opacity-50')}
                        onClick={() => {
                                if (i !== imgIx) {
                                    clearTimeout(timeout.current);
                                    setImgIndex(i)
                                }
                            }}
                        >
                            <span key={i}
                                
                                className={'bg-light h-2 w-2 rounded-full inline-block' }>
                            </span>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
