import { A } from './baseComponents';

export function Footer(props) {
    return (
        <div className='mx-auto pb-4 text-medium text-sm self-end mt-auto'>
            <span>Join our
            <A href="https://discord.gg/amoebits/" className="mx-1" >
                discord
            </A>
            or contact us on twitter
            <A href="https://twitter.com/amoebits" className='mx-1' >
                here
            </A> or
            <A href="https://twitter.com/flawmz" className='mx-1' >
                here.
            </A>
            </span>
        </div>
    )
}
