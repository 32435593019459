import { Img, Span, Button } from './baseComponents';
import { ConnectButton } from './web3/connectButton';
import flux from '@aust/react-flux';

export function Header(props) {
    let logged_in = flux.connectButton.useState('text') !== 'Connect Wallet';

    return (
        <div className={props.className + ''} >
            <div
                className='flex items-center cursor-pointer select-none'
                onClick={() => { console.log('home');flux.dispatch('page/change', '') }}
            >
                <Img name='logo' className='ml-8 w-16 h-auto inline-block m-0 rounded-sm' />
                <Span className='ml-4 text-xl'>
                    <span className='text-medium'>Meeb</span>
                    Stop
                </Span>
            </div>

            <div className='ml-auto mr-8'>
                {logged_in &&
                    <Button
                        className='mr-8'
                        text='Tools'
                        onClick={() => { flux.dispatch('page/change', 'tools') }}
                    />
                }
                {logged_in &&
                    <Button
                        className='mr-8'
                        text='Get Amoeboy'
                        onClick={() => { flux.dispatch('page/change', 'mint') }}
                    />
                }
                {logged_in && 
                    <Button
                        className='mr-8'
                        text='My Amoeboy'
                        onClick={() => { flux.dispatch('page/change', 'upgrade') }}
                    />
                }

                {!logged_in &&
                    <ConnectButton
                        className='mr-8'
                        real_text='Tools'
                        cb={() => { flux.dispatch('page/change', 'tools') }}
                    />
                }
                {!logged_in &&
                    <Button
                        className='mr-8'
                        text='Get Amoeboy'
                        onClick={() => { flux.dispatch('page/change', 'mint') }}
                    />
                }
                {!logged_in && 
                    <ConnectButton
                        className='mr-8'
                        real_text='My Amoeboy'
                        cb={() => { flux.dispatch('page/change', 'upgrade') }}
                    />
                }

                <ConnectButton />
            </div>
        </div>
    )
}
