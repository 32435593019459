import flux from '@aust/react-flux';
import { Img } from './baseComponents';

export function Loading(props) {
    const open = flux.loading.useState('open');

    let closeNice = () => {
        setTimeout(() => {
            flux.dispatch('loading/open', false);
        }, 150);
    }

    const opacity = !open ? 'opacity-0 z-0 pointer-events-none' : 'z-20';

    return (
        <div id='modal_stage' className={'transition-opacity ' + opacity}>Loading</div>
    )
}
