import * as sw3 from '@solana/web3.js';
import { decodeMetadata } from './metaAccount.js';

//const amoeboy_auth = "DuNNEyxhzziAq9bQGnUoroCzsQCv43EQuQ7tmieWKRHs"
const amoeboy_auth = "J3LmmLjKFb8TUbMQcMzLrtG4AcBDgtBYRcVKnN2wnF1Y"
//const upgrade_auth = "HHLn6iMFgMqbT2KTGuiLtbakaRhLoFiqKq3zSW6BE7QY"
const upgrade_auth = "DGqdRsujTePvuoVLUaAsLV78kJ6hZ5iqtBTpCcXJPm5y"
const meta_program = new sw3.PublicKey('metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s');

export async function getTokenList() {
    let token_accounts = await getTokenAccounts(window.wallet.publicKey.toBase58());

    token_accounts = token_accounts.filter(async (e) => {
        let ta = e.account.data.parsed.info.tokenAmount,
        mint = new sw3.PublicKey(e.account.data.parsed.info.mint);

        if (ta.amount === "1" && ta.decimals === 0) {
            let supply = await window.connection.getTokenSupply(mint);
            return supply.value.amount === "1";
        }
    });

    let meta_keys = [];

    for (let i = 0, l = token_accounts.length; i < l; i++) {
        let ta = token_accounts[i].account.data.parsed.info;
        let mint_key = new sw3.PublicKey(ta.mint);

        meta_keys.push((await sw3.PublicKey.findProgramAddress(
            [
                new Uint8Array([109,101,116,97,100,97,116,97]),
                meta_program.toBuffer(),
                mint_key.toBuffer()
            ],
            meta_program
        ))[0].toBase58())
    }

    let meta_accounts = [],
    slice = meta_keys.slice(0,100),
    iter = 0;

    while (slice.length >  0) {
        slice = meta_keys.slice(iter * 100, iter + 1 * 100);
        meta_accounts = meta_accounts.concat(await getMultipleAccounts(slice));
        iter++
    }

    console.log(meta_accounts);

    meta_accounts = meta_accounts.filter((e) => e !== null );

    console.log(meta_accounts);

    let all_tokens = meta_accounts.map((e) => {
        let b64 = e.data[0],
        data = b64toUInt8(b64),
        mdata = decodeMetadata(data);

        return mdata;
    });

    let amoeboys = meta_accounts.filter((e) => {
        let b64 = e.data[0],
        data = b64toUInt8(b64),
        mdata = decodeMetadata(data);

        e.data = mdata;
        e.data.mint = new sw3.PublicKey(mdata.mint);

        for (let i = 0, l = mdata.data.creators.length; i < l; i ++) {
            let pk = new sw3.PublicKey(mdata.data.creators[i].address);
            if (pk.toBase58() === amoeboy_auth && mdata.data.creators[i].verified) {
                return true;
            }
        }
        return false;
    });

    let upgrades = meta_accounts.filter((e) => {
        let mdata = e.data;

        for (let i = 0, l = mdata.data.creators.length; i < l; i ++) {
            let pk = new sw3.PublicKey(mdata.data.creators[i].address);
            if (pk.toBase58() === upgrade_auth && mdata.data.creators[i].verified) {
                return true;
            }
        }
        return false;
    });

    return [amoeboys, upgrades, all_tokens];
}

async function getMultipleAccounts(accounts) {
    let body = JSON.stringify({
        jsonrpc: "2.0",
        id: 1,
        method: "getMultipleAccounts",
        params: [
            accounts
        ]
    });

    let res = await wrapRPC(body);
console.log(res);
    return res.result?.value || [];
}

async function getTokenAccounts(public_key) {
    let body = JSON.stringify({
        jsonrpc: "2.0",
        id: 1,
        method: "getTokenAccountsByOwner",
        params: [
            public_key,
            {
                programId: "TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA"
            },
            {
                encoding: "jsonParsed"
            }
        ]
    });

    let res = await wrapRPC(body);
    return res.result.value;
}

async function wrapRPC(body) {
    return fetch(window.connection._rpcEndpoint,
        {
            headers: { 'Content-Type': 'application/json' },
            method: "POST",
            body
        }
    ).then(res => {
        let rs = res.body,
        reader = rs.getReader();
        return new ReadableStream({
            start(controller) {
            function push() {
                reader.read().then( ({done, value}) => {
                    if (done) {
                        controller.close();
                        return;
                    }
                    controller.enqueue(value);
                    push();
                })
            }

            push();
            }
        });
    })
    .then(stream => {
        return new Response(stream, { headers: { "Content-Type": "text/html" } }).text();
    })
    .then(streamed_res => {
        return JSON.parse(streamed_res);
    });
}

function b64toUInt8(b64) {
    const bin = window.atob(b64);

    return new Uint8Array(Array.from(bin).map((e, i) => { return bin.charCodeAt(i); }));
}
