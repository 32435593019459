import { Button, Img } from './baseComponents.js';
import { useState, useEffect } from 'react';
import flux from '@aust/react-flux';

export function Amoeboy(props) {
    let selected = flux.amb.useState('index');

    useEffect(() => {
        if (selected === props.i) {
            flux.dispatch('amb/index', props.i);
        }
    }, [selected, props.i]);

    return (
        <ChildComp
            onClick={() => {
                flux.dispatch('amb/index', props.i);
            }}
            {...props}
            className={'min-w-max flex mx-auto cursor-pointer'}
            width={256}
            selected={selected === props.i}
        />
    );
}

export function Upgrades(props) {
    return (
        <ChildComp {...props} width={190} className='inline-flex' />
    );
}

function ChildComp(props) {
    const [local_dat, setData] = useState(false);

    useEffect(() => {
        if (!local_dat) {
            fetch(props.data.uri)
            .then(res => res.json())
            .then(data => {
                setData(data);
            })
            .catch(err => {
                console.log(err);
            });
        }
    }, [local_dat, props.data]);

    return (
        <div onClick={props.onClick} className={props.className + ' w-auto bg-transp-75 rounded-b-md m-2 mb-4 flex-col items-center' } >
            <Img
                width={props.width}
                className='rounded-t rounded-b-sm'
                src={local_dat.image}
                onLoad={() => {
                    if (local_dat !== false && props.i !== undefined) {
                        flux.dispatch('amb/new_meta', props.i, local_dat);
                    }
                }}
            />
            <span
                className={
                (props.selected
                    ? 'underline text-white '
                    : '') +
                    ' mb-4 mt-4'}
            >
                    {props.data.name}
            </span>
        </div>
    )
}

export function UpgradeParentHolder(props) {
    const [tab, updateTab] = useState('default');

    return (
        <div className={props.className + ' flex flex-col items-center min-w-lg min-h-36 h-fit border-2 border-opacity-20 bg-transp border-gray-600 p-2 rounded mr-4'}>
            {props.options && 
                <UpgradeOptions cb={updateTab} />
            }
            {props.name && <h2 className='mt-2 underline text-lg'>{props.name}</h2>}
            {tab === 'default' ?
                props.children
                :
                <Inspect />
            }
        </div>
    )
}

export function Inspect(props) {
    const index = flux.amb.useState('index');
    const metadata = flux.amb.selectState('getMeta', index);

    if (!metadata) {
        return (
            <div className='w-full flex flex-col items-center justify-center text-gray-300 mt-4' >
                <span>You need to buy or mint an Amoeboy!</span>
            </div>
        )
    }

    return (
        <div className='w-full flex flex-col items-center justify-center' >
            <h2 className=''>{metadata.name}</h2>
            {metadata.attributes.map((e) => {
                return <span>{e.trait_type}: {e.value}</span>
            })}
        </div>
    )
}

export function UpgradeDivHolder(props) {
    return (
        <div className='mt-4 w-fit mx-auto text-gray-300 max-h-132 overflow-hidden overflow-y-scroll ssw-0'>
            {props.children}
        </div>
    )
}

export function UpgradeOptions(props) {
    const [selected, setSel] = useState(1);

    return (
        <div className='flex flex-row justify-center'>
            <Button
                onClick={() => {setSel(1); props.cb('default') }}
                className={(selected === 1 ?  'text-white ul_o ' : ' text-gray-500 ') + 'ml-8 mr-8'}
                text="Potential Upgrades" />
            <Button
                onClick={() => {setSel(2); props.cb('inspect') }}
                className={(selected === 2 ? 'text-white ul_o ' : ' text-gray-500 ') + 'ml-8 mr-8'}
                text="Inspect" />
        </div>
    )
}
