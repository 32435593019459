import * as sw3 from '@solana/web3.js';
import { Token } from '@solana/spl-token';
import flux from '@aust/react-flux';

export async function runMintContract(mintProgram, indexAccount, mintAccountCb, accountsCb) {
    if (mintAccountCb === null) {
        mintAccountCb = () => { return sw3.Keypair.generate() };
    }

    let connection = window.connection,
    bh             = await connection.getRecentBlockhash(),
    our_wallet     = new sw3.PublicKey('AmbtTL5LS42RFL1ZL5QQan8ZSyn27pvVoCbFYF2eTwyH'),
    minter_program = new sw3.PublicKey(mintProgram),
    index          = new sw3.PublicKey(indexAccount),
    token_program  = new sw3.PublicKey('TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA'),
    meta_program   = new sw3.PublicKey('metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s'),
    associated_program = new sw3.PublicKey("ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL"),
    payer_pkey     = new sw3.PublicKey(window.wallet.publicKey.toBase58()),
    mint_kp        = await mintAccountCb(payer_pkey),
    token_key      = (await sw3.PublicKey.findProgramAddress(
        [
            payer_pkey.toBuffer(),
            token_program.toBuffer(),
            mint_kp.publicKey.toBuffer()
        ],
        associated_program
    ))[0],
    meta_key       = (await sw3.PublicKey.findProgramAddress(
        [
            new Uint8Array([109,101,116,97,100,97,116,97]),
            meta_program.toBuffer(),
            mint_kp.publicKey.toBuffer()
        ],
        meta_program
    ))[0],
    auth_key       = (await sw3.PublicKey.findProgramAddress(
        [
            new Uint8Array([97,109,111,101,98,105,116,95,109,105,110,116,101,114]),
            minter_program.toBuffer(),
        ],
        minter_program
    ))[0],
    sys_key  = new sw3.PublicKey('11111111111111111111111111111111'),
    rent_key = new sw3.PublicKey('SysvarRent111111111111111111111111111111111');

    let account_0 = {pubkey: index,             isSigner: false, isWritable: true},
    account_1     = {pubkey: payer_pkey,        isSigner: true,  isWritable: true},
    account_2     = {pubkey: our_wallet,        isSigner: false, isWritable: true},
    account_3     = {pubkey: token_key,         isSigner: false, isWritable: true},
    account_4     = {pubkey: mint_kp.publicKey, isSigner: false, isWritable: true},
    account_5     = {pubkey: meta_key,          isSigner: false, isWritable: true},
    account_6     = {pubkey: meta_program,      isSigner: false, isWritable: false},
    account_7     = {pubkey: rent_key,          isSigner: false, isWritable: false},
    account_8     = {pubkey: auth_key,          isSigner: false, isWritable: true},
    account_9     = {pubkey: token_program,     isSigner: false, isWritable: false},
    account_10    = {pubkey: sys_key,           isSigner: false, isWritable: false};

    let mintAccount = sw3.SystemProgram.createAccount({
        fromPubkey: payer_pkey,
        newAccountPubkey: mint_kp.publicKey,
        lamports: 1461600,
        space: 82,
        programId: token_program
    }),
    tokenAccount = Token.createAssociatedTokenAccountInstruction(
        associated_program,
        token_program,
        mint_kp.publicKey,
        token_key,
        payer_pkey,
        payer_pkey,
    ),
    create_token = Token.createInitMintInstruction(
        token_program,
        mint_kp.publicKey,
        0,
        payer_pkey,
        null,
    ),
    mint_into_token_account = Token.createMintToInstruction(
        token_program,
        mint_kp.publicKey,
        token_key,
        payer_pkey,
        [],
        1,
    );

    let accounts = [account_0, account_1, account_2, account_3, account_4, account_5, account_6, account_7, account_8, account_9, account_10];

    if (accountsCb) {
        accounts = await accountsCb(accounts);
    }

    let instruction = new sw3.TransactionInstruction({
        keys: accounts,
        programId: minter_program
    });

    let transaction = new sw3.Transaction().add(
        mintAccount,
        create_token,
        tokenAccount,
        mint_into_token_account,
        instruction,
    );

    transaction.recentBlockhash = bh.blockhash;
    transaction.feePayer = window.wallet.publicKey;

    if (mint_kp.secretKey !== undefined) {
        transaction.sign(mint_kp);
    }

    try {
        let signedTransaction = await window.wallet.signTransaction(transaction),
        sig = await connection.sendRawTransaction(signedTransaction.serialize(), { skipPreflight: true }),
        conf = await connection.confirmTransaction(sig, 'confirmed');

        if (conf.value.err === null) {
            flux.dispatch('notices/success', 'Mint completed!');
        } else {
            console.log(sig);
            flux.dispatch('notices/error', 'Mint failed!');
        }
    } catch (e) {
        if (e.message !== 'User rejected the request.') {
            flux.dispatch('notices/error', 'Mint failed!');
        }
    }
}
