import { Header  } from './Header';
import { Footer  } from './Footer';
import { Body    } from './Body';
import { Notices } from './Notices';
import { Modal   } from './Modal';
import { Loading } from './Loading';
import { Theme   } from './Theme';
import * as sw3 from '@solana/web3.js';

function Main(props) {
    window.connection = new sw3.Connection("https://patient-withered-forest.solana-devnet.quiknode.pro/585faa3d9fc3bcc1ad3621f799c936bae8fbd365/");
    //window.connection = new sw3.Connection("https://ssc-dao.genesysgo.net/");
    window.sw3 = sw3;

    return (
        <Theme className='text-white font-sans' >
            <Notices/>
            <Modal/>
            <Loading/>
            <section className='max-w-6xl container w-auto flex mx-auto flex-col z-10 h-auto min-h-screen'>
                <Header className=' rounded-sm h-20 w-full flex items-center select-none' />
                <Body className='mt-4 mb-4 flex flex-col' />
                <Footer/>
            </section>
        </Theme>
    )
}

function App() {
    return <Main />
}

export default App;
