import { setModal } from './web3/connectButton';
import * as sw3 from '@solana/web3.js';
import { runMintContract } from './web3/mintContract';
import { useState, useEffect, useRef } from 'react';
import { Button, Img } from './baseComponents.js';
import flux from '@aust/react-flux';

export function MassTransfer({tokens}) {
    const [recipient, setRecipient]  = useState('');
    const [conf,      setConf]       = useState('');

    return (
        <>
            <div
                className='flex items-start justify-evenly w-full'
            >
                <div className='flex flex-col justify-center items-center'>
                    <h2>Your NFTs.</h2>
                    <span className='mt-2'>Select to <span className='font-italic'>EXCLUDE</span> them.</span>
                    <div
                        className='mt-8'
                    >
                        {tokens.map(e => { return ( <NFT metadata={e} /> ) })}
                    </div>
                </div>
                <div className='flex items-center flex-col w-48ch'>
                    <span>Recipient</span>
                    <input
                        className='text-black w-full text-center mt-2 h-8'
                        type='text'
                        onChange={(el) => { setRecipient(el.target.value) }}
                    />
                    <span className='mt-2'>Confirm Address</span>
                    <input
                        className='text-black w-full text-center mt-2 h-8'
                        type='text'
                        onChange={(el) => { setConf(el.target.value) }}
                    />
                </div>
                <Button
                    className='mt-4 mr-8'
                    text='Transfer'
                    onClick={() => {
                        if (recipient.length < 43) {
                            flux.dispatch('notices/error', 'Enter a wallet.');
                        } else if (recipient !== conf) {
                            flux.dispatch('notices/error', 'Confirmed wallet doesn\'t match.');
                        } else {
                            const to_transfer =
                                Array.from(document.querySelectorAll('div[data-mint][data-selected="1"]'))
                                .map((e) => { return new sw3.PublicKey(e.dataset.mint) })
                            transferNFTs(to_transfer, recipient)
                        }
                    }}
                />
            </div>
        </>
    )
}

async function transferNFTs(mints, recipient) {
    const token_program      = new sw3.PublicKey('TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA')
    const associated_program = new sw3.PublicKey('ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL')
    const payer_pkey         = new sw3.PublicKey(window.wallet.publicKey.toBase58())
    const recipient_key      = new sw3.PublicKey(recipient)
    const meeb               = new sw3.PublicKey('AMBTaiqVukuex8ap2BpFviPBcPxZyPvAMYQsrXJh8MjU')

    try {
    flux.dispatch('loading/open', true);

    let base_accounts = [];
    base_accounts[0] = {pubkey: payer_pkey,    isSigner: true,  isWritable: true }
    base_accounts[1] = {pubkey: token_program, isSigner: false, isWritable: false}
    base_accounts[2] = {pubkey: recipient_key, isSigner: false, isWritable: false}

    let txs         = [];
    let count       = 0;
    let transaction = new sw3.Transaction();
    let accounts    = Array.from(base_accounts);

    for (let i = 0, l = mints.length; i < l; i++) {
        let largest     = (await window.connection.getTokenLargestAccounts(mints[i])).value
        let payer_token = largest[0].address;

        // cant transfer this type. woops
        if (largest[1] !== undefined && largest[1].amount > 0) { continue; }

        accounts.push({pubkey: payer_token, isSigner: false, isWritable: true})

        if (accounts.length == 27) {
            transaction.add(
                new sw3.TransactionInstruction({
                    keys: accounts,
                    programId: meeb,
                    data: new Uint8Array([1, accounts.length - 3])
                })
            )

            txs.push(transaction);

            transaction = new sw3.Transaction();
            accounts    = Array.from(base_accounts);
        }
        count++;
    }

    if (accounts.length > 3) {
        transaction.add(
            new sw3.TransactionInstruction({
                keys: accounts,
                programId: meeb,
                data: new Uint8Array([1,accounts.length - 3])
            })
        )

        txs.push(transaction);
    }

    console.log(txs, count);

    asyncTxns(txs)
    .then((e) => {
        flux.dispatch('notices/success', 'Success!');
    })
    .catch((e) => {
        console.log(e);
        flux.dispatch('notices/error', 'Failure');
    })
    .finally((e) => {
        flux.dispatch('loading/open', false);
        flux.dispatch('page/change', 'massTransfer', true);
    });
    } catch (e) {
        flux.dispatch('loading/open', false);
    }
}

async function asyncTxns(txs) {
    let bh = (await window.connection.getRecentBlockhash()).blockhash;
    txs = txs.map((e) => {
        e.recentBlockhash = bh;
        e.feePayer        = window.wallet.publicKey;

        return window.wallet.signTransaction(e);
    });

    txs = await Promise.all(txs)
    .then((res) => {
        res = res.map((e) => {
            return sw3.sendAndConfirmRawTransaction(
                window.connection,
                e.serialize(),
                { commitment: 'finalized', skipPreflight: true }
            );
        });
        return res;
    })
    .catch((e) => {
        throw e;
    })

    return Promise.all(txs)
    .catch((e) => {
        throw e;
    })
}

function NFT({metadata}) {
    const [isExcluded, exclude] = useState(false);

    return (
        <div
            key={metadata.data.name}
            data-mint={(new sw3.PublicKey(metadata.mint)).toBase58()}
            data-selected={isExcluded ? 0 : 1}
            onClick={() => { exclude(!isExcluded) }}
            className={(isExcluded ? 'opacity-50 ' : '') + ' rounded-sm select-none w-full cursor-pointer m-2 p-1 border-2 border-slate-400'}
        >
            <span>{metadata.data.name}</span>
        </div>
    )
}
